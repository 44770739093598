'use strict';

var searchBtn = $('#search');
var searchFlyout = $('#search-flyout');
var header = $('.header-sticky');
var topHeaderHeight = $('.header-top').outerHeight();
var $closeSearch = $('#search-flyout').find('.close-icon');

module.exports = function () {

    function openSearch() {
    	searchBtn.addClass('open');
    	searchFlyout.slideToggle();
    	searchFlyout.css('top', topHeaderHeight );
    	header.find('.header-search input[name="q"]').focus().val('');
    }
    function closeSearch() {
    	searchFlyout.slideToggle();
    	searchBtn.removeClass('open');
    }
    searchBtn.removeClass('open');
    searchBtn.off('click').on('click', searchBtn, function(e) {
        e.preventDefault();
        if (!searchBtn.hasClass('open')) {
            openSearch();
        } else {
            closeSearch();
        }
    });
    $closeSearch.click(function (e) {
        e.preventDefault();
        closeSearch();
    });

	$(window).resize( function (){
		topHeaderHeight = $('.header-top').outerHeight();
		searchFlyout.css('top', topHeaderHeight );
	});
	
    $('#main').click(function(e) { 
        if ($('#search-flyout').is(':visible')) {
            closeSearch();
        }        
    });

    $('.search-icon').on('click touchstart', function(e) {
    	if ($('#q-search').val() === '') {
    		e.preventDefault();
        }
    });
}
