'use strict';

function init() {
	
    var form = $('#ContactForm');

	$('select').on('change', function() {
		if ($(this).find('option:selected').val() == null || $(this).find('option:selected').val() == '') {
		    $(this).removeClass('valid');
		} else {
			$(this).addClass('valid');
	    }
	});
	
	$('input, select').focusout(function() {
		if (!$(this).hasClass('required') && $(this).val() == '') {
			$(this).removeClass('error').removeClass('valid');
		} else {
			$(this).removeClass('error').addClass('valid');
        }
	});
   	
	form.on('click', 'button', function(e) {
		$('#ContactForm .required').each(function() {
			if (!$(this).hasClass('required') && $(this).val() == '') {
				$(this).removeClass('error').removeClass('valid');
			} else {
				$(this).removeClass('error').addClass('valid');
	        }
        });
	
		
	});
	
    // Profanity Check
    $('[name$="_contactus_message"]').addClass('Profanity');
}

exports.init = function() {
    init();
};